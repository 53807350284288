// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Float from "rescript/lib/es6/belt_Float.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactFeather from "react-feather";
import * as Utils$Coronate from "../Utils.bs.js";
import * as Router$Coronate from "../Router.bs.js";
import * as Data_Rounds$Coronate from "../Data/Data_Rounds.bs.js";
import * as Webapi__Dom__Element from "rescript-webapi/src/Webapi/Dom/Webapi__Dom__Element.bs.js";
import VisuallyHidden from "@reach/visually-hidden";

function makeDateInput(date) {
  var year = String(date.getFullYear());
  var rawMonth = date.getMonth();
  var rawDate = date.getDate();
  var month = rawMonth < 9.0 ? "0" + String(rawMonth + 1.0) : String(rawMonth + 1.0);
  var day = rawDate < 10.0 ? "0" + String(rawDate) : String(rawDate);
  return "" + year + "-" + month + "-" + day + "";
}

function PageTourneySetup(Props) {
  var tournament = Props.tournament;
  var tourney = tournament.tourney;
  var roundList = tourney.roundList;
  var date = tourney.date;
  var name = tourney.name;
  var setTourney = tournament.setTourney;
  var match = React.useState(function () {
        return /* NotEditing */2;
      });
  var setEditing = match[1];
  var editing = match[0];
  var nameInput = React.useRef(null);
  var dateInput = React.useRef(null);
  var focusRef = function (myref) {
    Belt_Option.map(Belt_Option.flatMap(Caml_option.nullable_to_opt(myref.current), Webapi__Dom__Element.asHtmlElement), (function (prim) {
            prim.focus();
          }));
  };
  React.useEffect((function () {
          switch (editing) {
            case /* Name */0 :
                focusRef(nameInput);
                break;
            case /* Date */1 :
                focusRef(dateInput);
                break;
            case /* NotEditing */2 :
                break;
            
          }
        }), [editing]);
  var changeByes = function (bye, s) {
    Curry._1(setTourney, {
          id: tourney.id,
          name: tourney.name,
          date: tourney.date,
          playerIds: tourney.playerIds,
          scoreAdjustments: tourney.scoreAdjustments,
          byeQueue: tourney.byeQueue,
          tieBreaks: tourney.tieBreaks,
          roundList: Data_Rounds$Coronate.updateByeScores(roundList, bye)
        });
    window.alert("Bye scores updated to " + s + ".");
  };
  var updateDate = function ($$event) {
    var rawDate = $$event.currentTarget.value;
    var match = rawDate.split("-");
    var match$1;
    if (match.length !== 3) {
      match$1 = [
        "2000",
        "01",
        "01"
      ];
    } else {
      var year = match[0];
      var month = match[1];
      var day = match[2];
      match$1 = [
        year,
        month,
        day
      ];
    }
    var year$1 = Belt_Float.fromString(match$1[0]);
    var month$1 = Belt_Float.fromString(match$1[1]);
    var date = Belt_Float.fromString(match$1[2]);
    if (year$1 !== undefined && month$1 !== undefined && date !== undefined) {
      return Curry._1(setTourney, {
                  id: tourney.id,
                  name: tourney.name,
                  date: new Date(year$1, month$1 - 1.0, date),
                  playerIds: tourney.playerIds,
                  scoreAdjustments: tourney.scoreAdjustments,
                  byeQueue: tourney.byeQueue,
                  tieBreaks: tourney.tieBreaks,
                  roundList: tourney.roundList
                });
    }
    
  };
  return React.createElement("div", {
              className: "content-area"
            }, editing !== 0 ? React.createElement("h1", {
                    style: {
                      textAlign: "left"
                    }
                  }, React.createElement("span", {
                        className: "inputPlaceholder"
                      }, name), " ", React.createElement("button", {
                        className: "button-ghost",
                        onClick: (function (param) {
                            Curry._1(setEditing, (function (param) {
                                    return /* Name */0;
                                  }));
                          })
                      }, React.createElement(ReactFeather.Edit, {}), React.createElement(VisuallyHidden, {
                            children: "Edit name"
                          }))) : React.createElement("form", {
                    className: "display-20",
                    style: {
                      textAlign: "left"
                    },
                    onSubmit: (function (param) {
                        Curry._1(setEditing, (function (param) {
                                return /* NotEditing */2;
                              }));
                      })
                  }, React.createElement("input", {
                        ref: nameInput,
                        className: "display-20",
                        style: {
                          textAlign: "left"
                        },
                        type: "text",
                        value: name,
                        onChange: (function ($$event) {
                            Curry._1(setTourney, {
                                  id: tourney.id,
                                  name: $$event.currentTarget.value,
                                  date: tourney.date,
                                  playerIds: tourney.playerIds,
                                  scoreAdjustments: tourney.scoreAdjustments,
                                  byeQueue: tourney.byeQueue,
                                  tieBreaks: tourney.tieBreaks,
                                  roundList: tourney.roundList
                                });
                          })
                      }), " ", React.createElement("button", {
                        className: "button-ghost",
                        onClick: (function (param) {
                            Curry._1(setEditing, (function (param) {
                                    return /* NotEditing */2;
                                  }));
                          })
                      }, React.createElement(ReactFeather.Check, {}))), editing !== 1 ? React.createElement("p", {
                    className: "caption-30"
                  }, React.createElement(Utils$Coronate.DateFormat.make, {
                        date: date
                      }), " ", React.createElement("button", {
                        className: "button-ghost",
                        onClick: (function (param) {
                            Curry._1(setEditing, (function (param) {
                                    return /* Date */1;
                                  }));
                          })
                      }, React.createElement(ReactFeather.Edit, {}), React.createElement(VisuallyHidden, {
                            children: "Edit date"
                          }))) : React.createElement("form", {
                    className: "caption-30",
                    onSubmit: (function (param) {
                        Curry._1(setEditing, (function (param) {
                                return /* NotEditing */2;
                              }));
                      })
                  }, React.createElement("input", {
                        ref: dateInput,
                        className: "caption-30",
                        type: "date",
                        value: makeDateInput(date),
                        onChange: updateDate
                      }), " ", React.createElement("button", {
                        className: "button-ghost",
                        onClick: (function (param) {
                            Curry._1(setEditing, (function (param) {
                                    return /* NotEditing */2;
                                  }));
                          })
                      }, React.createElement(ReactFeather.Check, {}))), React.createElement("h2", undefined, "Change all bye scores"), React.createElement("button", {
                  "aria-describedby": "score-desc",
                  onClick: (function (param) {
                      changeByes(/* Full */0, "1");
                    })
                }, "Change byes to 1"), " ", React.createElement("button", {
                  "aria-describedby": "score-desc",
                  onClick: (function (param) {
                      changeByes(/* Half */1, "½");
                    })
                }, "Change byes to ½"), " ", React.createElement("button", {
                  "aria-describedby": "score-desc",
                  onClick: (function (param) {
                      changeByes(/* Zero */2, "0");
                    })
                }, "Change byes to 0"), React.createElement("p", {
                  className: "caption-30",
                  id: "score-desc"
                }, "This will update ", React.createElement("em", undefined, "all"), " bye matches which have been previously scored in this\n      tournament, including ones that were added manually. To change the default\n      bye value in future matches, go to the ", React.createElement(Router$Coronate.Link.make, {
                      children: "app options",
                      to_: /* Options */4
                    }), "."));
}

var make = PageTourneySetup;

export {
  make ,
}
/* react Not a pure module */
