// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Float from "rescript/lib/es6/belt_Float.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactFeather from "react-feather";
import * as Numeral$Coronate from "./Externals/Numeral.bs.js";
import LogoSvg from "../graphics/logo.svg";
import BmcButtonSvg from "../graphics/bmc-button.svg";
import QG_transparentPng from "../graphics/QG_transparent.png";

function ascend(cmp, getter, a, b) {
  return Curry._2(cmp, getter(a), getter(b));
}

function descend(cmp, getter, a, b) {
  return Curry._2(cmp, getter(b), getter(a));
}

function swap(arr, idx1, idx2) {
  var match = Belt_Array.get(arr, idx1);
  var match$1 = Belt_Array.get(arr, idx2);
  if (match !== undefined && match$1 !== undefined) {
    arr[idx1] = Caml_option.valFromOption(match$1);
    arr[idx2] = Caml_option.valFromOption(match);
    return arr;
  } else {
    return arr;
  }
}

var $$Array = {
  swap: swap
};

var logo = LogoSvg;

var buyMeACoffee = BmcButtonSvg;

var qGlogo = QG_transparentPng;

var WebpackAssets = {
  logo: logo,
  buyMeACoffee: buyMeACoffee,
  qGlogo: qGlogo
};

var formatter = new (Intl.DateTimeFormat)(["en-US"], {
      day: "2-digit",
      month: "short",
      year: "numeric"
    });

function Utils$DateFormat(Props) {
  var date = Props.date;
  return React.createElement("time", {
              dateTime: date.toISOString()
            }, formatter.format(date));
}

var formatter$1 = new (Intl.DateTimeFormat)(["en-US"], {
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      month: "short",
      year: "numeric"
    });

function Utils$DateTimeFormat(Props) {
  var date = Props.date;
  var timeZone = Props.timeZone;
  var formatter$2 = timeZone !== undefined ? new (Intl.DateTimeFormat)(["en-US"], {
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          month: "short",
          timeZone: timeZone,
          year: "numeric"
        }) : formatter$1;
  return React.createElement("time", {
              dateTime: date.toISOString()
            }, formatter$2.format(date));
}

function Utils$Panel(Props) {
  var children = Props.children;
  var classNameOpt = Props.className;
  var styleOpt = Props.style;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var style = styleOpt !== undefined ? Caml_option.valFromOption(styleOpt) : ({});
  return React.createElement("div", {
              className: "utils__panel " + className + "",
              style: style
            }, children);
}

var Panel = {
  make: Utils$Panel
};

function Utils$PanelContainer(Props) {
  var children = Props.children;
  var classNameOpt = Props.className;
  var styleOpt = Props.style;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var style = styleOpt !== undefined ? Caml_option.valFromOption(styleOpt) : ({});
  return React.createElement("div", {
              className: "utils__panels " + className + "",
              style: style
            }, children);
}

var PanelContainer = {
  make: Utils$PanelContainer
};

function Utils$Notification(Props) {
  var children = Props.children;
  var kindOpt = Props.kind;
  var tooltipOpt = Props.tooltip;
  var classNameOpt = Props.className;
  var styleOpt = Props.style;
  var kind = kindOpt !== undefined ? kindOpt : /* Generic */3;
  var tooltip = tooltipOpt !== undefined ? tooltipOpt : "";
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var style = styleOpt !== undefined ? Caml_option.valFromOption(styleOpt) : ({});
  var match;
  switch (kind) {
    case /* Success */0 :
        match = [
          React.createElement(ReactFeather.Check, {}),
          "utils__notification-success"
        ];
        break;
    case /* Warning */1 :
        match = [
          React.createElement(ReactFeather.AlertTriangle, {}),
          "utils__notification-warning"
        ];
        break;
    case /* Error */2 :
        match = [
          React.createElement(ReactFeather.AlertTriangle, {}),
          "utils__notification-error"
        ];
        break;
    case /* Generic */3 :
        match = [
          React.createElement(ReactFeather.Info, {}),
          ""
        ];
        break;
    
  }
  return React.createElement("div", {
              className: "utils__notification-container " + match[1] + " " + className + "",
              style: style
            }, React.createElement("div", {
                  "aria-label": tooltip,
                  className: "utils__notification-icon",
                  title: tooltip
                }, match[0]), React.createElement("div", {
                  className: "utils__notification-text"
                }, children));
}

var $$Notification = {
  make: Utils$Notification
};

function Utils$TestId(Props) {
  var children = Props.children;
  var testId = Props.testId;
  return React.cloneElement(children, {
              "data-testid": testId
            });
}

var TestId = {
  make: Utils$TestId
};

function validateFieldOnBlurWithValidator(input, fieldStatus, validator) {
  if (fieldStatus) {
    return ;
  } else {
    return /* Dirty */{
            _0: Curry._1(validator, input)
          };
  }
}

function exposeFieldResult(fieldStatus) {
  if (fieldStatus) {
    return fieldStatus._0;
  }
  
}

var FormHelper = {
  validateFieldOnBlurWithValidator: validateFieldOnBlurWithValidator,
  exposeFieldResult: exposeFieldResult
};

Numeral$Coronate.registerFormat("fraction", Numeral$Coronate.Format.make(Numeral$Coronate.RegExps.make(/(1\/2)/, /(1\/2)/), (function (value, _format, _roundingFunction) {
            var whole = Math.floor(value);
            var remainder = value - whole;
            var fraction = remainder !== 0.25 ? (
                remainder !== 0.5 ? (
                    remainder !== 0.75 ? "" : "¾"
                  ) : "½"
              ) : "¼";
            var stringedWhole = whole === 0.0 && fraction !== "" ? "" : String(whole);
            return stringedWhole + fraction;
          }), (function (value) {
            return Belt_Option.getWithDefault(Belt_Float.fromString(value), 0.0);
          })));

var github_url = "https://github.com/johnridesabike/coronate";

var license_url = "https://github.com/johnridesabike/coronate/blob/master/LICENSE";

var issues_url = "https://github.com/johnridesabike/coronate/issues/new";

var changelog_url = "https://github.com/johnridesabike/coronate/blob/master/CHANGELOG.md";

var faq_url = "https://github.com/johnridesabike/coronate/blob/master/docs/faq.md";

var DateFormat = {
  make: Utils$DateFormat
};

var DateTimeFormat = {
  make: Utils$DateTimeFormat
};

export {
  github_url ,
  license_url ,
  issues_url ,
  changelog_url ,
  faq_url ,
  ascend ,
  descend ,
  $$Array ,
  WebpackAssets ,
  DateFormat ,
  DateTimeFormat ,
  Panel ,
  PanelContainer ,
  $$Notification ,
  TestId ,
  FormHelper ,
}
/* logo Not a pure module */
