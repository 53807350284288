// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as $$Promise from "@ryyppy/rescript-promise/src/Promise.bs.js";
import Numeral from "numeral";
import * as Belt_Map from "rescript/lib/es6/belt_Map.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Db$Coronate from "./Db.bs.js";
import * as ReactFeather from "react-feather";
import * as Hooks$Coronate from "./Hooks.bs.js";
import * as Utils$Coronate from "./Utils.bs.js";
import * as Window$Coronate from "./Window.bs.js";
import * as Data_Id$Coronate from "./Data/Data_Id.bs.js";
import * as Octokit$Coronate from "./Externals/Octokit.bs.js";
import * as DemoData$Coronate from "./testdata/DemoData.bs.js";
import * as TestData$Coronate from "./testdata/TestData.bs.js";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";
import * as Data_Config$Coronate from "./Data/Data_Config.bs.js";
import * as Data_Player$Coronate from "./Data/Data_Player.bs.js";
import * as HtmlEntities$Coronate from "./HtmlEntities.bs.js";
import NetlifyAuthProviders from "netlify-auth-providers";
import * as Data_Tournament$Coronate from "./Data/Data_Tournament.bs.js";

function getDateForFile(param) {
  var date = new Date();
  return [
            String(date.getFullYear()),
            Numeral(date.getMonth() + 1.0).format("00"),
            Numeral(date.getDate()).format("00")
          ].join("-");
}

function decodeOptions(json) {
  var d = Belt_Option.getExn(Js_json.decodeObject(json));
  var dict = Belt_Option.getExn(Belt_Option.flatMap(Js_dict.get(d, "players"), Js_json.decodeObject));
  var dict$1 = Belt_Option.getExn(Belt_Option.flatMap(Js_dict.get(d, "tournaments"), Js_json.decodeObject));
  return {
          config: Data_Config$Coronate.decode(Belt_Option.getExn(Js_dict.get(d, "config"))),
          players: Belt_Map.map(Data_Id$Coronate.$$Map.fromStringArray(Js_dict.entries(dict)), Data_Player$Coronate.decode),
          tournaments: Belt_Map.map(Data_Id$Coronate.$$Map.fromStringArray(Js_dict.entries(dict$1)), Data_Tournament$Coronate.decode)
        };
}

function encodeOptions(data) {
  var map = Belt_Map.map(data.players, Data_Player$Coronate.encode);
  var map$1 = Belt_Map.map(data.tournaments, Data_Tournament$Coronate.encode);
  return Js_dict.fromArray([
              [
                "config",
                Data_Config$Coronate.encode(data.config)
              ],
              [
                "players",
                Js_dict.fromArray(Curry._1(Data_Id$Coronate.$$Map.toStringArray, map))
              ],
              [
                "tournaments",
                Js_dict.fromArray(Curry._1(Data_Id$Coronate.$$Map.toStringArray, map$1))
              ]
            ]);
}

function PageOptions$LastBackupDate(Props) {
  var date = Props.date;
  if (date.getTime() === 0.0) {
    return "Never";
  } else {
    return React.createElement(Utils$Coronate.DateTimeFormat.make, {
                date: date
              });
  }
}

var dateFormatter = new (Intl.DateTimeFormat)(["en-US"], {
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      month: "2-digit",
      year: "2-digit"
    });

var site_id = process.env.NETLIFY_ID;

var netlifyopts = site_id !== undefined ? ({
      site_id: site_id
    }) : ({});

function PageOptions$GistOpts(Props) {
  var exportData = Props.exportData;
  var configDispatch = Props.configDispatch;
  var loadJson = Props.loadJson;
  var match = Db$Coronate.useAuth(undefined);
  var authDispatch = match[1];
  var auth = match[0];
  var minify = Hooks$Coronate.useBool(true);
  var match$1 = React.useState(function () {
        return [];
      });
  var setGists = match$1[1];
  var cancelAllEffects = {
    contents: false
  };
  var handleAuthError = function (e) {
    console.error(e);
    if (!cancelAllEffects.contents) {
      Curry._1(authDispatch, /* Reset */1);
    }
    return Promise.resolve(undefined);
  };
  var loadGistList = function (auth) {
    var token = auth.github_token;
    if (token === "") {
      return Promise.resolve(Curry._1(setGists, (function (param) {
                        return [];
                      })));
    } else {
      return $$Promise.$$catch(Octokit$Coronate.Gist.list(token).then(function (data) {
                      if (!cancelAllEffects.contents) {
                        Curry._1(setGists, (function (param) {
                                return data;
                              }));
                        if (!Belt_Array.some(data, (function (x) {
                                  return x.id === auth.github_gist_id;
                                }))) {
                          return Curry._1(authDispatch, /* RemoveGistId */0);
                        } else {
                          return ;
                        }
                      }
                      
                    }), handleAuthError);
    }
  };
  React.useEffect((function () {
          loadGistList(auth);
          return (function (param) {
                    cancelAllEffects.contents = true;
                  });
        }), [auth.github_token]);
  var match$2 = auth.github_token;
  var tmp = match$2 === "" ? React.createElement("button", {
          onClick: (function (e) {
              e.preventDefault();
              new NetlifyAuthProviders(netlifyopts).authenticate({
                    provider: "github",
                    scope: "gist"
                  }, (function (err, data) {
                      if (data !== undefined) {
                        if (!cancelAllEffects.contents) {
                          return Curry._1(authDispatch, {
                                      TAG: /* SetGitHubToken */0,
                                      _0: data.token
                                    });
                        } else {
                          return ;
                        }
                      } else {
                        if (err == null) {
                          console.error("Something wrong happened.");
                        } else {
                          console.error(err);
                        }
                        return ;
                      }
                    }));
            })
        }, "Log in with GitHub") : React.createElement("a", {
          href: "https://github.com/settings/connections/applications/" + process.env.GITHUB_APP_ID
        }, "Change or remove your GitHub access ", React.createElement(ReactFeather.ExternalLink, {}));
  var github_token = auth.github_token;
  var tmp$1 = github_token === "" ? null : React.createElement("div", undefined, React.createElement("button", {
              onClick: (function (param) {
                  $$Promise.$$catch(Octokit$Coronate.Gist.create(github_token, encodeOptions(exportData), minify.state).then(function (newGist) {
                              if (!cancelAllEffects.contents) {
                                Curry._1(authDispatch, {
                                      TAG: /* SetGistId */1,
                                      _0: newGist.data.id
                                    });
                                Curry._1(configDispatch, {
                                      TAG: /* SetLastBackup */6,
                                      _0: new Date()
                                    });
                              }
                              window.alert("Data saved.");
                            }).then(function (param) {
                            return loadGistList(auth);
                          }), (function (e) {
                          window.alert("Backup failed. Check your GitHub credentials.");
                          return handleAuthError(e);
                        }));
                })
            }, "Create a new gist"), React.createElement("p", {
              className: "caption-30"
            }, "Or select an existing gist."), React.createElement("select", {
              value: auth.github_gist_id,
              onBlur: (function (e) {
                  var id = e.currentTarget.value;
                  Curry._1(authDispatch, {
                        TAG: /* SetGistId */1,
                        _0: id
                      });
                }),
              onChange: (function (e) {
                  var id = e.currentTarget.value;
                  Curry._1(authDispatch, {
                        TAG: /* SetGistId */1,
                        _0: id
                      });
                })
            }, React.createElement("option", {
                  value: ""
                }, "No gist selected."), Belt_Array.map(match$1[0], (function (param) {
                    var id = param.id;
                    return React.createElement("option", {
                                key: id,
                                value: id
                              }, param.name, " | updated ", dateFormatter.format(param.updated_at));
                  }))), React.createElement("p", undefined, React.createElement("button", {
                  disabled: auth.github_gist_id === "",
                  onClick: (function (param) {
                      var id = auth.github_gist_id;
                      if (id === "") {
                        console.error("Gist ID is blank.");
                        return ;
                      }
                      $$Promise.$$catch(Octokit$Coronate.Gist.write(github_token, id, encodeOptions(exportData), minify.state).then(function (param) {
                                  if (!cancelAllEffects.contents) {
                                    Curry._1(configDispatch, {
                                          TAG: /* SetLastBackup */6,
                                          _0: new Date()
                                        });
                                  }
                                  window.alert("Data saved.");
                                }).then(function (param) {
                                return loadGistList(auth);
                              }), (function (e) {
                              window.alert("Backup failed. Check your GitHub credentials or try a different gist.");
                              return handleAuthError(e);
                            }));
                    })
                }, "Backup to this gist"), " ", React.createElement("button", {
                  disabled: auth.github_gist_id === "",
                  onClick: (function (param) {
                      var id = auth.github_gist_id;
                      if (id === "") {
                        console.error("Gist ID is blank.");
                        return ;
                      }
                      $$Promise.$$catch(Octokit$Coronate.Gist.read(github_token, id).then(Curry.__1(loadJson)), (function (e) {
                              window.alert("That data is invalid! A more helpful error message could not be written yet.");
                              return handleAuthError(e);
                            }));
                    })
                }, "Load from this gist")), React.createElement("p", {
              className: "caption-30"
            }, React.createElement("label", undefined, React.createElement("input", {
                      checked: minify.state,
                      type: "checkbox",
                      onChange: (function (param) {
                          if (minify.state) {
                            return Curry._1(minify.setFalse, undefined);
                          } else {
                            return Curry._1(minify.setTrue, undefined);
                          }
                        })
                    }), " Minify output.")));
  return React.createElement("div", undefined, React.createElement("h3", undefined, "Backup to GitHub"), React.createElement("p", {
                  className: "caption-30"
                }, "With a GitHub account, you can save your data to a ", React.createElement("a", {
                      href: "https://gist.github.com/"
                    }, "gist ", React.createElement(ReactFeather.ExternalLink, {})), ". Note that gists can be " + HtmlEntities$Coronate.ldquo + "secret" + HtmlEntities$Coronate.rdquo + " but are always \n        publicly accessible. For more information, ", React.createElement("a", {
                      href: "https://docs.github.com/en/github/writing-on-github/creating-gists"
                    }, "refer to the gist documentation on GitHub ", React.createElement(ReactFeather.ExternalLink, {})), "."), React.createElement("p", undefined, tmp), tmp$1);
}

function PageOptions(Props) {
  var windowDispatchOpt = Props.windowDispatch;
  var windowDispatch = windowDispatchOpt !== undefined ? windowDispatchOpt : (function (param) {
        
      });
  var match = Db$Coronate.useAllTournaments(undefined);
  var tourneysDispatch = match.dispatch;
  var tournaments = match.items;
  var match$1 = Db$Coronate.useAllPlayers(undefined);
  var playersDispatch = match$1.dispatch;
  var players = match$1.items;
  var match$2 = React.useState(function () {
        return "";
      });
  var setText = match$2[1];
  var text = match$2[0];
  var match$3 = Db$Coronate.useConfig(undefined);
  var configDispatch = match$3[1];
  var config = match$3[0];
  React.useEffect((function () {
          Curry._1(windowDispatch, {
                TAG: /* SetTitle */2,
                _0: "Options"
              });
          return (function (param) {
                    Curry._1(windowDispatch, {
                          TAG: /* SetTitle */2,
                          _0: ""
                        });
                  });
        }), [windowDispatch]);
  var exportData = React.useMemo((function () {
          return {
                  config: config,
                  players: players,
                  tournaments: tournaments
                };
        }), [
        config,
        tournaments,
        players
      ]);
  var exportDataURI = encodeURIComponent(JSON.stringify(encodeOptions(exportData)));
  React.useEffect((function () {
          var encoded = encodeOptions(exportData);
          var json = JSON.stringify(encoded, null, 2);
          Curry._1(setText, (function (param) {
                  return json;
                }));
        }), [
        exportData,
        setText
      ]);
  var loadData = function (tournaments, players, config) {
    Curry._1(tourneysDispatch, {
          TAG: /* SetAll */2,
          _0: tournaments
        });
    Curry._1(configDispatch, {
          TAG: /* SetState */5,
          _0: config
        });
    Curry._1(playersDispatch, {
          TAG: /* SetAll */2,
          _0: players
        });
    window.alert("Data loaded.");
  };
  var loadJson = function (json) {
    try {
      var match = decodeOptions(JSON.parse(json));
      return loadData(match.tournaments, match.players, match.config);
    }
    catch (raw_e){
      var e = Caml_js_exceptions.internalToOCamlException(raw_e);
      console.error(e);
      window.alert("That data is invalid! A more helpful error message could not be written yet.");
      return ;
    }
  };
  var handleText = function ($$event) {
    $$event.preventDefault();
    loadJson(text);
  };
  var handleFile = function ($$event) {
    $$event.preventDefault();
    var reader = new FileReader();
    var onload = function (ev) {
      var data = ev.target.result;
      try {
        var match = decodeOptions(JSON.parse(data));
        return loadData(match.tournaments, match.players, match.config);
      }
      catch (raw_e){
        var e = Caml_js_exceptions.internalToOCamlException(raw_e);
        console.error(e);
        window.alert("That data is invalid! A more helpful error message could not be written yet.");
        return ;
      }
    };
    reader.onload = onload;
    reader.readAsText(Belt_Option.getWithDefault(Belt_Array.get($$event.currentTarget.files, 0), ""));
    $$event.currentTarget.value = "";
  };
  var reloadDemoData = function ($$event) {
    $$event.preventDefault();
    loadData(DemoData$Coronate.tournaments, DemoData$Coronate.players, DemoData$Coronate.config);
  };
  var loadTestData = function ($$event) {
    $$event.preventDefault();
    loadData(TestData$Coronate.tournaments, TestData$Coronate.players, TestData$Coronate.config);
  };
  var handleTextChange = function ($$event) {
    var newText = $$event.currentTarget.value;
    Curry._1(setText, (function (param) {
            return newText;
          }));
  };
  var match$4 = config.byeValue;
  var match$5 = config.byeValue;
  var match$6 = config.byeValue;
  var s = Data_Config$Coronate.aliasToOption(config.whiteAlias);
  var s$1 = Data_Config$Coronate.aliasToOption(config.blackAlias);
  return React.createElement(Window$Coronate.Body.make, {
              children: React.createElement("div", {
                    className: "content-area"
                  }, React.createElement("h2", undefined, "Bye settings"), React.createElement("form", undefined, React.createElement("p", {
                            className: "caption-30"
                          }, "Select the default score given to a player who takes a bye."), React.createElement("div", {
                            style: {
                              display: "flex"
                            }
                          }, React.createElement("label", {
                                className: "body-20",
                                style: {
                                  marginRight: "16px"
                                }
                              }, "Full (", React.createElement("span", {
                                    className: "monospace"
                                  }, "1"), ") ", React.createElement("input", {
                                    checked: match$4 === 0,
                                    type: "radio",
                                    onChange: (function (param) {
                                        Curry._1(configDispatch, {
                                              TAG: /* SetByeValue */4,
                                              _0: /* Full */0
                                            });
                                      })
                                  })), React.createElement("label", {
                                className: "body-20",
                                style: {
                                  marginRight: "16px"
                                }
                              }, "Half (", React.createElement("span", {
                                    className: "monospace"
                                  }, "½"), ") ", React.createElement("input", {
                                    checked: match$5 === 1,
                                    type: "radio",
                                    onChange: (function (param) {
                                        Curry._1(configDispatch, {
                                              TAG: /* SetByeValue */4,
                                              _0: /* Half */1
                                            });
                                      })
                                  })), React.createElement("label", {
                                className: "body-20"
                              }, "None (", React.createElement("span", {
                                    className: "monospace"
                                  }, "0"), ") ", React.createElement("input", {
                                    checked: match$6 >= 2,
                                    type: "radio",
                                    onChange: (function (param) {
                                        Curry._1(configDispatch, {
                                              TAG: /* SetByeValue */4,
                                              _0: /* Zero */2
                                            });
                                      })
                                  })))), React.createElement("h2", undefined, "Player labels"), React.createElement("form", undefined, React.createElement("p", {
                            className: "caption-30"
                          }, "Use custom labels for opposing players."), React.createElement("label", {
                            className: "body-20"
                          }, "Player 1: ", React.createElement("input", {
                                placeholder: Data_Config$Coronate.aliasToStringWhite(Data_Config$Coronate.$$default),
                                type: "text",
                                value: s !== undefined ? s : "",
                                onChange: (function ($$event) {
                                    Curry._1(configDispatch, {
                                          TAG: /* SetWhiteAlias */7,
                                          _0: $$event.target.value
                                        });
                                  })
                              })), React.createElement("label", {
                            className: "body-20"
                          }, "Player 2: ", React.createElement("input", {
                                placeholder: Data_Config$Coronate.aliasToStringBlack(Data_Config$Coronate.$$default),
                                type: "text",
                                value: s$1 !== undefined ? s$1 : "",
                                onChange: (function ($$event) {
                                    Curry._1(configDispatch, {
                                          TAG: /* SetBlackAlias */8,
                                          _0: $$event.target.value
                                        });
                                  })
                              }))), React.createElement("h2", undefined, "Manage data"), React.createElement("p", {
                        className: "caption-20"
                      }, "Last export: ", React.createElement(PageOptions$LastBackupDate, {
                            date: config.lastBackup
                          })), React.createElement(PageOptions$GistOpts, {
                        exportData: exportData,
                        configDispatch: configDispatch,
                        loadJson: loadJson
                      }), React.createElement("h3", undefined, "Backup locally"), React.createElement("p", undefined, React.createElement("a", {
                            download: "coronate-" + (getDateForFile(undefined) + ".json"),
                            href: "data:application/json," + exportDataURI,
                            onClick: (function (param) {
                                Curry._1(configDispatch, {
                                      TAG: /* SetLastBackup */6,
                                      _0: new Date()
                                    });
                              })
                          }, React.createElement(ReactFeather.Download, {}), " Export data to a file.")), React.createElement("label", {
                        htmlFor: "file"
                      }, "Load data from a file:"), React.createElement("input", {
                        id: "file",
                        name: "file",
                        type: "file",
                        onChange: handleFile
                      }), React.createElement("h2", undefined, "Danger zone"), React.createElement("p", {
                        className: "caption-30"
                      }, "I hope you know what you're doing..."), React.createElement("button", {
                        onClick: reloadDemoData
                      }, "Reset demo data (this erases everything else)"), " ", process.env.NODE_ENV !== "production" ? React.createElement("button", {
                          onClick: loadTestData
                        }, "Load testing data") : null, React.createElement("h3", undefined, "Advanced: manually edit data"), React.createElement("form", {
                        onSubmit: handleText
                      }, React.createElement("textarea", {
                            className: "pages__text-json",
                            spellCheck: false,
                            cols: 50,
                            name: "playerdata",
                            rows: 25,
                            value: text,
                            onChange: handleTextChange
                          }), React.createElement("p", undefined, React.createElement("input", {
                                type: "submit",
                                value: "Load"
                              })))),
              windowDispatch: windowDispatch
            });
}

var make = PageOptions;

export {
  make ,
}
/* dateFormatter Not a pure module */
